import { navigate } from "gatsby"
import React from "react"
import Layout from "~/components/Layout"
import Spacer from "~/components/reusable/svgs/Spacer"
import Wrapper from "~/components/reusable/Wrapper"
import Seo from "~/components/SEO"
import { ThankYouWrapper } from "~/components/ThankYou"
import useTemplateContent from "~/hooks/useTemplateContent"
import { isBrowser, replaceEmail } from "~/utils/helpers"
import parse from "html-react-parser"

const ThankYou = ({ location }: { location: any }) => {
  if (!isBrowser()) {
    return null
  }
  const { state } = location
  const content = useTemplateContent("suppliersCallBackThankYou")
  const meta = useTemplateContent("thankYouContactUsMeta")

  if (!state?.formSubmitted) {
    navigate("/become-a-supplier")
    return null
  }

  // if state.emailAddress exists, show the email address in content.text
  // otherwise, show the default content.text
  const text = state?.emailAddress
    ? replaceEmail(content.text, state.emailAddress)
    : content.text

  return (
    <Layout path="/become-a-supplier/thank-you/">
      <Seo title={meta?.title} description={meta?.description} />
      <div className={`relative mt-[-153px] bg-theme-yellow pt-32`}>
        <div
          className={`dot absolute right-[-160px] top-[-170px] z-[0] h-[550px] w-[550px] rounded-full bg-theme-green md:right-[-320px] md:top-[-340px] md:h-[1058px] md:w-[1058px]`}
        />
        <Wrapper className={`relative z-[2]`}>
          <ThankYouWrapper
            content={content}
            leftContent={
              <>
                <h2 className="mb-3 px-3 text-h2 font-bold tracking-tight md:text-h1">
                  {content.title}
                </h2>
                <p className={`px-3 leading-8 [&_a]:font-bold`}>
                  {parse(text)}
                </p>
              </>
            }
            rightContent={
              <>
                {content.steps.map((step: { text: string }, index: number) => (
                  <div
                    key={index}
                    className={`mt-4 flex items-center rounded-3xl border py-[6px] ${
                      index === 0
                        ? `border-theme-greenlighter bg-theme-green-lighter`
                        : `border-theme-orange`
                    }`}
                  >
                    <span
                      className={`count mr-6 w-14 border-r-2 text-center text-h4 leading-[72px] ${
                        index === 0
                          ? `border-theme-green text-theme-green`
                          : `border-theme-orange text-theme-orange`
                      }`}
                    >
                      0{index + 1}
                    </span>
                    <span
                      className={`max-w-[204px] text-normal font-bold tracking-[-0.016em]`}
                    >
                      {step.text}
                    </span>
                  </div>
                ))}
              </>
            }
          />
        </Wrapper>
        <Spacer top={`yellow`} bottom={`black`} />
      </div>
    </Layout>
  )
}

export default ThankYou
